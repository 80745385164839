<template>
  <div id="app">
    <headerNav></headerNav>
    <asideBox></asideBox>

    <router-view />

    <div class="g_btnBox btnBox">
      <doubleBtn label="LIGHTNING MM" @btnClick="jumpOut('https://www.lightningmm.com')"></doubleBtn>
      <doubleBtn label="CONTACT US" @btnClick="showContactUsDialog = true"></doubleBtn>
    </div>

    <div class="g_dialog" v-if="showContactUsDialog">
      <div class="dialogMask" @click="showContactUsDialog = false"></div>
      <div class="dialogBox">
        <div class="dialogHeader">
          CONTACT US
          <div class="closeBtn" @click="showContactUsDialog = false">
            <img src="@/assets/images/close.png" alt="x">
          </div>
        </div>
        <div class="dialogContent">
          <doubleBtn label="@JS7337" @btnClick="jumpOut('https://t.me/jessie7337')"></doubleBtn>
          <doubleBtn label="@ObeliskRik" @btnClick="jumpOut('https://t.me/ObeliskRik')"></doubleBtn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ResizeMixin from './utils/homeResize'
  import headerNav from '@/components/headerNav/headerNav.vue'
  import asideBox from '@/components/asideBox/asideBox.vue'
  import doubleBtn from '@/components/doubleBtn/doubleBtn.vue'

  import bus from "@/utils/bus.js";

  export default ({
    mixins: [ResizeMixin],
    components: {
      headerNav,
      asideBox,
      doubleBtn,
    },
    data() {
      return {
        showContactUsDialog: false,
      }
    },
    created() {
      bus.$on('openContactUs', () => { this.showContactUsDialog = true })
    },
    methods: {
      jumpOut(url) {
        window.open(url)
      },
    },
  })
</script>
<style lang="less">
  @import url('@/style/common.less');
</style>