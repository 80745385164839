<template>
    <button class="doubleBtn" @click="btnClick">
        {{label}}
        <img src="@/assets/images/rightArrowWhite.png" alt="">
        <span class="foreground">
            {{label}}
            <img src="@/assets/images/rightArrowBlack.png" alt="">
        </span>
    </button>
</template>
<script>
export default {
    props: ['label'],
    methods:{
        btnClick(){
            this.$emit('btnClick')
        },
    }
}
</script>
<style lang="less" scoped>
@import url('./doubleBtn.less');
</style>
