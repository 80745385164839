<template>
    <div class="asideBox" v-if="device == 'mobile' && isOpen">
        <div class="asideHeader">
            <img @click="close" src="@/assets/images/close.png" alt="x">
        </div>
        <ul class="navBox">
            <li v-for="(item, index) in navList" :key="index" :class="{ activeNav: activeNav == item.path }"
                @click="jump(item.path)">
                {{ item.name }}
                <span></span>
            </li>
        </ul>

        <div class="footerBox">
            <div class="row1">
                <span class="icon" @click="openTelegram">
                    <img src="@/assets/images/telegram.png" alt="">
                </span>
                <span class="icon">
                    <a href="https://twitter.com/lightningMMbot" target="_blank">
                        <img src="@/assets/images/twitter.png" alt="">
                    </a>
                </span>
            </div>
            <div class="row2">
                <span @click="jump('/privacy')">PRIVACY POLICY</span>
            </div>
            <div class="row3">
                2023 BY LIGHTING LTD
            </div>
        </div>
    </div>
</template>
<script>
    import bus from "@/utils/bus.js";
    import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters(['navList', 'device', 'isOpen']),
            activeNav() {
                return this.$route.path
            },
        },
        methods: {
            openTelegram() {
                bus.$emit('openContactUs')
            },
            close() {
                this.$store.dispatch('app/setIsOpen', false)
            },
            jump(path) {
                if (path != this.$route.path) {
                    this.$router.push({ path })
                    this.close()
                }
            },
        },
    }
</script>
<style lang="less" scoped>
    @import url('./asideBox.less');
</style>