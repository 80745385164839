const state = {
  device: 'desktop',// desktop mobile
  // 菜单栏
  isOpen: false,
  // 导航栏
  navList: [
    // { name: 'Home', pasgeSize: 2 },

    { path: '/about', name: 'ABOUT US', pasgeSize: 3 },
    { path: '/service', name: 'SERVICE', pasgeSize: 1 },
    { path: '/product', name: 'PRODUCT', pasgeSize: 1 },
    { path: '/FAQ', name: 'FAQ', pasgeSize: 1 },
  ],

  // 交易所列表
  exchangeList: [
    'Binance',
    'Bithumb',
    'OKEX',
    'KuCoin',
    'Spooky Swap',
    'Quickswap',
    'MEXC',
    'Bybit',
    'Pancake Swap',
    'Gate.io',
    'Huobi Global',
    'Uniswap',
    'Bitget',
    'DigiFinex',
    'CoinW',
    'BitMEX',
    'BigONE',
    'LBank',
    'Coinstore',
    'XT',
    'BitMart',
  ]
}

const mutations = {
  // 分辨率判定
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_IS_OPEN: (state, isOpen) => {
    state.isOpen = isOpen
  },
}

const actions = {
  // 分辨率判定
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setIsOpen({ commit }, isOpen) {
    commit('SET_IS_OPEN', isOpen)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
