import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/about.vue'),
    meta: {
      title: 'ABOUT US',
    },
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service/service.vue'),
    meta: {
      title: 'SERVICE',
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/product.vue'),
    meta: {
      title: 'PRODUCT',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/privacy.vue'),
    meta: {
      title: 'PRODUCT',
    },
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('@/views/FAQ/FAQ.vue'),
    meta: {
      title: 'FAQ',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

// 跳转后返回页面顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
  if (to.path === '/FAQ'){
    return
  }else{
    next()
  }
})

export default router
