<template>
    <div class="headerNavBox">
        <div class="headerNav" :class="{navHidden:navHidden&&device != 'mobile',serivebg:activeNav=='/service'}">
            <div class="headerNavCon">
                <div class="navLeft">
                    <router-link to="/">
                        <img src="@/assets/images/logo.png" alt="logo">
                    </router-link>
                </div>
                <ul class="navRight" v-if="device != 'mobile'">
                    <li v-for="(item,index) in navList" :key="index" :class="{activeNav:activeNav==item.path}">
                        <router-link :to="item.path">
                            {{item.name}}
                            <span></span>
                        </router-link>
                    </li>
                </ul>
                <div class="navRight" @click="openNav" v-else>
                    <img src="@/assets/images/nav.png" alt="三">
                </div>
            </div>
            <div class="bottomBorder">
                <div class="stepNow" :style="{ width: stepWidth+'%' }"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                stepWidth: 0,
                navHidden: false,
                prevScrollPos: 0,//记录每次滚动时滚动条旧位置，滚动完毕时为最新的
                htmlHeight: 0,
                innerHeight: 0,
            };
        },
        computed: {
            ...mapGetters(['navList', 'device']),
            activeNav() {
                return this.$route.path
            },
        },
        watch: {
            activeNav(newValue, oldValue) {
                this.calculateHeight()
            },
        },
        mounted() {
            this.prevScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            window.addEventListener('scroll', this.scrollChange);
            window.addEventListener('resize', this.calculateHeight);
            this.calculateHeight()
        },
        methods: {
            scrollChange() {
                const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if (this.device != 'mobile') {

                    if (this.prevScrollPos > currentScrollPos && currentScrollPos <= 90) {
                        // 向上滚动，显示导航栏 //超过90不显示
                        this.navHidden = false;
                    } else {
                        // 向下滚动，隐藏导航栏
                        this.navHidden = true;
                    }

                }
                this.prevScrollPos = currentScrollPos;
                this.stepWidth = (this.innerHeight + this.prevScrollPos) / this.htmlHeight * 100
            },
            openNav() {
                this.$store.dispatch('app/setIsOpen', true)
            },
            calculateHeight() {
                this.innerHeight = window.innerHeight
                this.htmlHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, window.innerHeight);
                this.stepWidth = (this.innerHeight + this.prevScrollPos) / this.htmlHeight * 100
            },
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.scrollChange);
            window.removeEventListener('resize', this.calculateHeight);
        },
    };
</script>
<style lang="less" scoped>
    @import url('./headerNav.less');
</style>