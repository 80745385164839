import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// rem适配
import './utils/rem';

// element
import 'element-ui/lib/theme-chalk/index.css';

import {
  // Loading,
  Button,
  Message,
} from 'element-ui';

Vue.use(Button);
// Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
